import * as React from "react";
import {
    Link, useLocation
  } from "react-router-dom";
import './Menu.scss';

function Menu() {
    
    const location = useLocation();
    const pathname = location.pathname;
  return (
    <nav className="menu">
      <ul>
        <li>
          <Link to="/"  className={pathname === "/" ? "selected" : ""}>About</Link>
        </li>
        <li>
          <Link to="/Services" className={pathname === "/Services" ? "selected" : ""}>Services</Link>
        </li>
        <li className="long">
          <Link to="/Qualifications" className={pathname === "/Qualifications" ? "selected" : ""}>Qualifications</Link>
        </li>
        <li>
          <Link to="/Prices" className={pathname === "/Prices" ? "selected" : ""}>Prices</Link>
        </li>
        <li>
          <Link to="/Contact" className={pathname === "/Contact" ? "selected" : ""}>Contact</Link>
        </li>
        <li>
          <Link to="/Links" className={pathname === "/Links" ? "selected" : ""}>Links</Link>
        </li>
      </ul>
    </nav>
  );
}

export default Menu;
