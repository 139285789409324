import * as React from "react";
import './Services.scss';

function Services() {
  return (
    <>
      <h1>Services</h1>
      <div className="textContent">
        <p>RECOMMENDED MINIMUM SERVICES FOR ALL HOMES TO BE PURCHASED:</p>
        <ul>
          <li>FULL INSPECTION</li>
          <li>RADON TESTING</li>
          <li>CAMERA INSPECTION OF MAIN SEWER DRAIN</li>
          <li>SEPTIC &amp; WELL INSPECTIONS (if applicable)</li>
          <li className="no-bullet">
            <p>We provide the following services:</p>
            <ul>
                <li>Full pre-purchase home inspections</li>
                <li>Partial inspections (Foundation, roof, etc.)</li>
                <li>Seller inspections</li>
                <li>New construction inspections</li>
                <li>Wood-destroying insect (pest) inspections</li>
                <li>Environmental testing (radon, mold, water, asbestos, lead)</li>
                <li>Septic and well inspections</li>
                <li>Camera inspections of main sewer drains</li>
                <li>Commercial inspections</li>
            </ul>
            <p>Our full pre-purchase inspection reports are Internet-based (see example in "Links" section of website) and include the following:</p>
            <ul>
                <li>Summary section called "Summary of Primary Concerns" where the major problems are discussed</li>
                <li>Typical repair cost ranges for the major problems found</li>
                <li>Detailed discussion of problems found, maintenance recommendations, component descriptions (e.g. age, condition, type), upgrade recommendations, positive aspects, etc.</li>
                <li>Photographs and graphics for clarification</li>
                <li>Links to pertinent websites for additional information</li>
                <li>Free 400-page home manual</li>
            </ul>
            <p>NEW CONSTRUCTION:&nbsp; Typically, builders do not want to read through a full inspection report because there is too much detail, some boiler-plate discussion, discussion of items for the buyer only, etc.&nbsp; Therefore, in addition to the Internet-based report discussed above, we provide a separate punch-list that is submitted to the builder.&nbsp; The punch-list contains items we believe are the responsibility of the builder.&nbsp;The punch-list is a format the builder is comfortable with because it is a list with only the details needed.&nbsp; It is much more likely the builder will address items that are provided in a list form as opposed to a report with paragraphs of information.&nbsp; The punch-list is included at no extra charge.</p>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Services;
