import * as React from "react";
import './Qualifications.scss';

function Qualifications()  {
  return (
    <>
      <h1>Qualifications</h1>
      <div className="textContent">
        <p>Lead Inspector:&nbsp; David Richardson</p>
        <ul>
          <li>Bachelor of Science in Civil Engineering - University of Michigan (1988)</li>
          <li>ASHI-Certified Inspector (American Society of Home Inspectors) - #113366</li>
          <li>State of Michigan Residential Builder License - #2101147875</li>
          <li>Full-time home inspector since 1995</li>
          <li>Thousands of inspections performed</li>
          <li>10 years experience in the civil engineering field</li>
          <li>Extensive home inspection course work</li>
        </ul>
        <p>Partner:&nbsp; Bill Richardson</p>
        <ul>
            <li>Bachelor of Science in Civil Engineering - University of Michigan (1963)</li>
            <li>35 years experience in the area of environmental engineering and science</li>
        </ul>
      </div>
    </>
  );
}

export default Qualifications;
