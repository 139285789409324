import * as React from "react";
import './PageNotFound.scss';

function PageNotFound() {
  return (
    <div>PageNotFound PAGE</div>
  );
}

export default PageNotFound;
