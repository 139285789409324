import * as React from "react";
import './About.scss';

function About()  {
  return (
    <>
      <h1>About</h1>
      <div className="textContent">
        <p>
          Milestone Inspections, LLC is a Southeast Michigan-based, full-service home inspection, testing, and consulting company built around the efforts of a father and 
          son Civil Engineer team. We have been performing inspections since 1990 and are certified by the American Society of Home Inspectors (ASHI). We perform very 
          thorough inspections and provide detailed Internet-based reports. Our full inspections include a&nbsp;400-page reference manual.&nbsp; &nbsp;In addition, we are 
          fully insured - we carry Errors &amp; Omissions and General Liability insurance.
        </p>
        <p className="p1">PHONE: (734) 748-7270</p>
        <p className="p1">EMAIL: <a href="mailto:Dave@milestoneinspections.com">Dave@milestoneinspections.com</a></p>
        <p >&nbsp;</p>
        <div>
          <a className="book-online" target="_blank" href="http://www.discoverhorizon.com/standard/book/Appt.aspx?c=609">
            BOOK ONLINE
          </a>
        </div>
        <p className="p2">
          <span className="p3">NOTE ABOUT BOOKING ONLINE</span>:&nbsp; Available inspection times are shown when you select a day to schedule.&nbsp; 
          If no times are shown, there are no openings for that day at that time.&nbsp; Also, you will receive a confirmation email and phone call after booking.&nbsp; 
          If we are closed, the phone confirmation will be upon our return (don’t worry, your inspection is scheduled until you cancel and there are no cancellation fees).
        </p>
      </div>
    </>
  );
}

export default About;
