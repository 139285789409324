import React, { Fragment } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import RotatingImage from '../RotatingImage/RotatingImage';
import Header from '../assets/images/header.jpg';
import './Layout.scss';
import Services from '../Services/Services';
import About from '../About/About';
import Menu from '../Menu/Menu';
import PageNotFound from '../PageNotFound/PageNotFound';
import SiteMap from '../SiteMap/SiteMap';
import FAQs from '../FAQs/FAQs';
import Contact from '../Contact/Contact';
import Links from '../Links/Links';
import Qualifications from '../Qualifications/Qualifications';
import Prices from '../Prices/Prices';
import MenuRight from '../MenuRight/MenuRight';

function Layout() {
  return (
    <Router>
      <div className="container">             
          <div className="header">
              <img src={Header} alt="Milestone Inspections" />
          </div>
          <div className="menu">
            <Menu></Menu>
          </div>
          <div className="content">
            <div className="main-content">
              <Fragment>
                <Routes>
                  <Route path="/" element={<About />} />
                  <Route path="/Services" element={<Services />} />
                  <Route path="/Qualifications" element={<Qualifications />} />
                  <Route path="/Prices" element={<Prices />} />
                  <Route path="/Contact" element={<Contact />} />
                  <Route path="/Links" element={<Links />} />
                  <Route path="/FAQs" element={<FAQs />} />
                  <Route path="/SiteMap" element={<SiteMap />} />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </Fragment>
            </div>
            <div className="sub-content">
              <MenuRight></MenuRight>
              <RotatingImage></RotatingImage>
            </div>
          </div>
      </div>
    </Router>
  );
}

export default Layout;
