import React from 'react';
import './App.scss';
import Layout from './Layout/Layout';

function App() {
  return (
    <>
      <Layout></Layout>
    </>
  );
}

export default App;
