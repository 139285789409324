import React from 'react';
import RightImageHome from '../assets/images/rightImageHome.jpg';
import SideImage2 from '../assets/images/SideImage2.jpg';
import SideImage3 from '../assets/images/SideImage3.jpg';
import SideImage4 from '../assets/images/SideImage4.jpg';
import SideImage5 from '../assets/images/SideImage5.jpg';
import './RotatingImage.scss';

function RotatingImage() {

    const rnd = Math.floor(Math.random() * 4);
    let img;
    switch (rnd) {
        case 4:
            img = SideImage5;
            break;
        case 3:
            img = SideImage4;
            break;
        case 2:
            img = SideImage3;
            break;
        case 1:
            img = SideImage2;
            break;
        default:
            img = RightImageHome;
            break;
    }

    return (
        <img src={img} alt="Home"></img>
    );
}

export default RotatingImage;
