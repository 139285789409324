import * as React from "react";
import './Prices.scss';

function Prices()  {
  return (
    <>
      <h1>Inspection Prices</h1>
      <div className="textContent bold-all">
        <p><u>Full Inspection ($):</u></p>
        <div>Up to 1250 sq. ft. = 425</div>
        <div>1251 to 1450 = 450</div>
        <div>1451 to 1650 = 495</div>
        <div>1651 to 2000 = 550</div>
        <div>2001 to 2500 = 595</div>
        <div>2501 to 3000 = 645</div>
        <div>3001 to 3500 = 695</div>
        <div>3501 to 4000 = 745</div>
        <div>4001 to 4500 = 795*</div>
        <div>4501 to 5000 = 895*</div>
        <div>&gt;5000 - call for price</div>
        <div className="double-spacing">*Extra Fee for every hour past 4 hours that an inspection lasts = $50 (capped at $100)</div>
        <div className="double-spacing"><u>Radon Testing</u>:&nbsp; $175 with full inspection; $195 separately</div>
        <div className="double-spacing"><u>Termite Inspection (with full inspection)</u>:&nbsp; $0</div>
        <div className="double-spacing"><u>Termite Report (if separate report required by bank)</u>: &nbsp; $100</div>
        <div className="double-spacing"><u>Termite Inspection Only</u>:&nbsp;  Contact licensed pest control company</div>
        <div className="double-spacing"><u>Re-inspection</u>:&nbsp; $200</div>
        <div className="double-spacing"><u>Single Item Inspection (e.g. roof only)</u>:&nbsp; $295</div>
        <div className="double-spacing"><u>Structure/Foundation Inspection Only</u>:&nbsp; $350</div>
        <div className="double-spacing"><u>Sewer Inspection with Camera</u>:&nbsp; $200</div>
        <div className="double-spacing"><u>Commercial Inspections</u>:&nbsp; call for price</div>
        <div className="double-spacing"><u>Other Environmental Testing or Inspections</u>:&nbsp; call for prices</div>
        <div className="double-spacing"><u>Septic &amp; Well Inspection</u>:&nbsp; call for prices</div>
        <div>&nbsp;PAYMENT DUE AT INSPECTION - CHECK, CASH, VENMO, AND ALL CREDIT CARDS ACCEPTED</div>
      </div>
    </>
  );
}

export default Prices;
