import * as React from "react";
import { Link } from "react-router-dom";
import './SiteMap.scss';

function SiteMap()  {
  return (
    <>
      <h1>Links</h1>
      <div className="textContent site-map">
        <ul>
          <li>
            <Link to="/">About</Link>
          </li>
          <li>
            <Link to="/Services">Services</Link>
          </li>
          <li className="long">
            <Link to="/Qualifications">Qualifications</Link>
          </li>
          <li>
            <Link to="/Prices">Prices</Link>
          </li>
          <li>
            <Link to="/Contact">Contact</Link>
          </li>
          <li>
            <Link to="/Links">Links</Link>
          </li>
          <li>
            <Link to="/FAQs">F.A.Q's</Link>
          </li>
          <li>
            <Link to="/SiteMap">Site Map</Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default SiteMap;
