import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import './MenuRight.scss';

function MenuRight() {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <nav className="menu-right">
        <ul>
          <li>
            <Link to="/FAQs" className={pathname === "/FAQs" ? "selected" : ""}>F.A.Q's</Link>
          </li>
          <li>
            <Link to="/SiteMap" className={pathname === "/SiteMap" ? "selected" : ""}>Site Map</Link>
          </li>
        </ul>
      </nav>
  );
}

export default MenuRight;
