import * as React from "react";
import './Links.scss';

function Links()  {
  return (
    <>
      <h1>Links</h1>
      <div className="textContent">
        <p>HELPFUL LINKS &amp; PHONE NUMBERS:</p>
        <p>
          <a href="./ExampleInspectionReport_MilestoneInspections.pdf"target="_blank" >Milestone Inspections Example Inspection Report</a>
          &nbsp;(Example inspection report)
        </p>
        <p><a target="_blank" href="http://www.epa.gov/radon">www.epa.gov/radon</a> (Environmental Protection Agency website - radon gas info.)</p>
        <p><a target="_blank" href="http://www.epa.gov/mold">www.epa.gov/mold</a> (EPA website - mold info.)</p>
        <p><a target="_blank" href="http://www.epa.gov/lead">www.epa.gov/lead</a> (EPA website - lead info.)</p>
        <p><a target="_blank" href="http://www.epa.gov/asbestos">www.epa.gov/asbestos</a> (EPA website - asbestos info.)</p>
        <p><a target="_blank" href="http://www.ashi.org/">www.ashi.org</a> (Society of Home Inspectors - find ASHI inspection standards, state inspection regulations, virtual inspections, consumer alerts, etc.)</p>
      </div>
    </>
  );
}

export default Links;
