import * as React from "react";
import './FAQs.scss';

function FAQs()  {
  return (
    <>
      <h1>F.A.Q.'s</h1>
      <div className="textContent">
        <ol>
          <li>
            <div className="faqQuestion">What is ASHI?</div>
            <div className="faqAnswer">
              ASHI is the American Society of Home Inspectors.&nbsp; ASHI is the oldest and most respected home inspector certification organization (established in 1976).&nbsp; ASHI sets the standard for home inspector certification.&nbsp; It takes years to become an ASHI-Certified Inspector.&nbsp; Before becoming certified an inspector must have performed at least 250 inspections and pass rigorous tests.&nbsp; Also, ASHI-Certified Inspectors must meet or exceed strict inspection standards and obtain ongoing education.
            </div>
          </li>
          <li>
            <div className="faqQuestion">What is the purpose of a home inspection?</div>
            <div className="faqAnswer">
              <p>The purpose of the home inspection is to:</p>
              <ol>
                  <li>Report the major problems in the home, including estimated repair costs</li>
                  <li>Educate the buyer about the home</li>
                  <li>Provide maintenance, upgrade, and repair recommendations</li>
                  <li>Provide a detailed report&nbsp;to document the&nbsp;inspection results and to provide a future reference</li>
              </ol>
              <p>The main purpose of a home inspection is to report the major problems found in the home.&nbsp; We focus your attention on the significant cost, safety, and structural concerns.&nbsp; In addition, we will discuss the age/condition/type of the main components and discuss how the house compares to houses of the same age/size (e.g. did we find more or less problems than normal).</p>
              <p>Also, the inspection is meant to be educational.&nbsp; We show you main shut-off valves, attic accesses, crawl space accesses, etc.&nbsp; We explain how to maintain the furnace, humidifier, water heater, air conditioning system, exterior, etc.&nbsp; A list of the positive aspects of the home is also provided (e.g. new roof, copper water piping, replacement windows, etc.).</p>
              <p>In addition to documenting the major problems, we discuss the minor concerns, upgrade recommendations, maintenance recommendations, and repair recommendations.</p>
              <p>Finally, a detailed report is&nbsp;provided (see separate F.A.Q. for report details).</p>
            </div>
          </li>
          <li>
            <div className="faqQuestion">What items are inspected?</div>
            <div className="faqAnswer">
              <p>A full home inspections covers all the major components of a home, including:</p>
              <ul>
                  <li>Roof</li>
                  <li>Exterior</li>
                  <li>Structure</li>
                  <li>Electrical</li>
                  <li>Heating</li>
                  <li>Cooling</li>
                  <li>Insulation and ventilation</li>
                  <li>Plumbing</li>
                  <li>Interior</li>
              </ul>
            </div>
          </li>
          <li>
            <div className="faqQuestion">How long does an inspection take?</div>
            <div className="faqAnswer">
              <p>Inspection of a typical 2000 sq. ft. house&nbsp;normally takes&nbsp;about 2.5 hours.&nbsp; The time varies with the number of problems found, the number of questions asked by the buyer, how many attics or crawl spaces are present, etc.</p>
            </div>
          </li>
          <li>
            <div className="faqQuestion">What type of report is provided?</div>
            <div className="faqAnswer"><p>The report is Internet-Based in PDF format.&nbsp; It can be saved to your computer or accessed via a link.&nbsp; The report includes photographs, graphics, and links to pertinent information.&nbsp; The last page of the report is a 400-age Homebook that can be saved to your computer.&nbsp; The Homebook contains detailed information on every component of a home, including maintenance information.&nbsp; The report also informs you of a free on-line home maintenance program.</p></div></li>
          <li>
            <div className="faqQuestion">Are we insured against errors?</div>
            <div className="faqAnswer"><p>Yes - we carry Errors &amp; Omissions and General Liability insurance.</p></div>
          </li>
          <li>
            <div className="faqQuestion">What does an inspection cost?</div>
            <div className="faqAnswer"><p>See the "<a href="./prices.php">Prices</a>" link above</p>
            </div>
          </li>
        </ol>

      </div>
    </>
  );
}

export default FAQs;
