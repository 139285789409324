import * as React from "react";
import './Contact.scss';

function Contact()  {
  return (
    <>
      <h1>Contact</h1>
      <div className="textContent">
        Dave Richardson<br />
        Phone: (734) 748-7270<br />
        Email: <a href="mailto:Dave@milestoneinspections.com">Dave@milestoneinspections.com</a>
      </div>
    </>
  );
}

export default Contact;
